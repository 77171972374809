
import coinsLottie from './assets/lottie/coins.json'

import lottie from 'lottie-web'
import anime from 'animejs'
import WOW from 'wow.js'

require('../css/main.css')
require('./page.css')

document.addEventListener('DOMContentLoaded', () => {
  console.log('DOMContentLoaded', 'page-home')
  // console.log('Image through require()', img)
})

function isElementInViewport (el) {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  )
}

window.onload = () => {
  document.body.classList.remove('before-load')
  new WOW().init()

  setTimeout(() => {
    document.querySelectorAll('.anim-vert-shrink').forEach((el, i) => {
      el.classList.remove('animated')

      setTimeout(() => {
        anime({
          targets: el,
          loop: true,
          easing: 'linear',
          direction: 'alternate',
          duration: 1000,
          // delay: anime.stagger(100),
          endDelay: -100,
          translateY: -20
        })
      }, i * 100)
    })

    // .add({
    //     translateY: 2
    // })
    // .add({
    //     translateY: 10
    // })
    // .add({
    //     translateY: 0,
    // });
  }, 1200)

  const coinsAnimation = lottie.loadAnimation({
    container: document.getElementById('coins-animation-container'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: coinsLottie
  })

  let circlesAnimations = []

  const circles = document.querySelectorAll('.numbers__circle')
  const circleMain = document.querySelector('.numbers__circle-main')

  document.querySelector('.numbers__circle-main').addEventListener('mouseenter', (e) => {
    const circles = document.querySelectorAll('.numbers__circle')
    const circleMain = document.querySelector('.numbers__circle-main')

    if (circlesAnimations.length > 0) {
      circlesAnimations.forEach(anim => {
        anim.pause()
      })
      circlesAnimations = []
    }

    circles.forEach((cI, index) => {
      let translateX = 0

      if (index <= 1) {
        translateX = circleMain.getBoundingClientRect().left - cI.getBoundingClientRect().left
      } else {
        translateX = circleMain.getBoundingClientRect().right - cI.getBoundingClientRect().right
      }

      const animation = anime({
        targets: cI,
        translateX,
        easing: 'easeInOutSine',
        duration: 200
      })
      animation.play()

      circlesAnimations.push(animation)
    })

    console.log(circlesAnimations)

    anime({
      targets: circleMain,
      easing: 'easeInOutSine',
      scale: 1.1,
      duration: 300
    }).play()
  })

  document.querySelector('.numbers__circle-main').addEventListener('mouseleave', (e) => {
    const circles = document.querySelectorAll('.numbers__circle')
    const circleMain = document.querySelector('.numbers__circle-main')

    console.log('mouseleave')

    if (circlesAnimations.length > 0) {
      circlesAnimations.forEach(anim => {
        anim.pause()
      })
      circlesAnimations = []
    }

    anime({
      targets: circleMain,
      easing: 'easeInOutSine',
      scale: 1,
      duration: 300
    })

    circles.forEach(cI => {
      const delay = parseInt(cI.dataset.delay)

      const animation = anime({
        targets: cI,
        translateX: 0,
        delay: delay,
        easing: 'easeOutExpo',
        duration: 200
      })
      animation.play()

      circlesAnimations.push(animation)
    })

    console.log(circlesAnimations)
  })

  document.querySelector('.m-menu__button').addEventListener('click', (e) => {
    document.querySelector('.m-menu__button').classList.toggle('opened')
    document.querySelector('.header__nav').classList.toggle('opened')
  })
}

window.onscroll = function () {
  document.querySelectorAll('.numbers__item-num.should-animate').forEach(el => {
    if (isElementInViewport(el)) {
      animateNumbers(el)
    }
  })
}

function animateNumbers (el) {
  const endValue = parseInt(el.dataset.endValue)
  const duration = el.dataset.duration ? parseInt(el.dataset.duration) : 2000
  console.log(el)

  anime({
    targets: el,
    textContent: endValue,
    easing: 'easeInOutQuad',
    round: 1,
    duration: duration,
    begin: function (anim) {
      el.classList.remove('should-animate')
    }
  })
}
